/* :root {
  --color-primary: #334155;
  --color-secondary: #059669;
} */


html, body{
  height: 100%;
  font-family: 'Nunito', sans-serif;
}

.logo{
  height: 27vh;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  margin: auto;
  margin-bottom: 0px;
  transform-origin: bottom;
  z-index: 10;
}

.max-h-large{
  max-height: 10000px
}

#content-wrap {
  height: 100%;
  min-height: 100vh;
  padding-bottom: 2.5rem;    /* Footer height */
}

@media only screen and (max-height: 645px) {

  .auth-button{
    display: none;

  }
}


.bold-text{
  font-weight: bolder;
  font-size: 1.2em;
}

.page{
  position: relative;
  min-height: 100vh;
}

#root{
  /* height: 100%; */
  min-height: 100vh;
}

.info-text{
  font-size: 0.9em;
  margin-left: 5em;
  margin-right: 5em;
  font-family: 'Open Sans Condensed', sans-serif;
}

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.1);
}

.overlay-wrapper{
  position: relative;
  height: 100%;
  min-height: 100vh;
}


.left-center-96{
  /* Firefox */
  left: -moz-calc(50% - 12rem);
  /* WebKit */
  left: -webkit-calc(50% - 12rem);
  /* Opera */
  left: -o-calc(50% - 12rem);
  /* Standard */
  left: calc(50% - 12rem);
  top: 10rem;
  }

.left-center-160{
  /* Firefox */
  left: -moz-calc(50% - 20rem);
  /* WebKit */
  left: -webkit-calc(50% - 20rem);
  /* Opera */
  left: -o-calc(50% - 20rem);
  /* Standard */
  left: calc(50% - 20rem);
}

.left-center-160-top-1{
  /* Firefox */
  left: -moz-calc(50% - 20rem);
  /* WebKit */
  left: -webkit-calc(50% - 20rem);
  /* Opera */
  left: -o-calc(50% - 20rem);
  /* Standard */
  left: calc(50% - 20rem);
  top: 1rem;
}

.left-center-200{
  /* Firefox */
  left: -moz-calc(50% - 50rem);
  /* WebKit */
  left: -webkit-calc(50% - 50rem);
  /* Opera */
  left: -o-calc(50% - 50rem);
  /* Standard */
  left: calc(50% - 50rem);
  top: 10rem;
}

.left-center-120-top-0{
  /* Firefox */
  left: -moz-calc(50% - 15rem);
  /* WebKit */
  left: -webkit-calc(50% - 15rem);
  /* Opera */
  left: -o-calc(50% - 15rem);
  /* Standard */
  left: calc(50% - 15rem);
  top: 0rem;
}

.left-center-120{
  /* Firefox */
  left: -moz-calc(50% - 15rem);
  /* WebKit */
  left: -webkit-calc(50% - 15rem);
  /* Opera */
  left: -o-calc(50% - 15rem);
  /* Standard */
  left: calc(50% - 15rem);
  top: 10rem;
}

.left-center-100{
  /* Firefox */
  left: -moz-calc(50% - 12.5rem);
  /* WebKit */
  left: -webkit-calc(50% - 12.5rem);
  /* Opera */
  left: -o-calc(50% - 12.5rem);
  /* Standard */
  left: calc(50% - 12.5rem);
  top: 10rem;
}

.overlay-dark{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.sidebar-link{
  border-left: 1px solid #ffffff;
  transition: 0.2s;
}

.sidebar-link:hover{
  /* border-left: 5px solid #E0E7FF; */
  border-left: 5px solid #ffffff;
  transition: 0.2s;
  /* background: #181c20; */
  color: white;
}

.sidebar-link:focus{
  outline: 0 !important;
}

.active-sidebar-link{
  /* border-left: 4px solid #E0E7FF; */
  border-left: 5px solid #ffffff;
  transition: 0.5s;
  background: black;
  color: white;
}

.custom-shadow{
  box-shadow: 3px 1px 5px rgba(0,0,0,0.25);
}

.auth-button{
  margin: 20px;
}



.custom-input:focus{
  outline: none;
  /* border: none;
  border-left: 5px solid black; */
  /* border-bottom: 1px solid black; */
  /* transition: 0.3s; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.landing-navbar{
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}

@keyframes bounce-2 {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-7px); }
  100% { transform: translateY(0); }
}

.loader,
.loader:before,
.loader:after {
  background: #000000;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: rgb(0, 0, 0);
  text-indent: -9999em;
  top: 50%;
  left: 50%;
  z-index: 10;
  position: absolute;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/* $midnight: #2c3e50; */
/* $clouds: #ecf0f1; */
/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);
  margin: 5px;
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}

.tab-label{
  width: 100%;
  color: white;
  overflow: hidden;
  height: 4em;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #2c3e50;
  font-weight: bold;
  cursor: pointer;
}

.tab-label:hover{
  background: rgb(35, 52, 68);
}

.tab:hover{
  background: rgb(35, 52, 68);
}

.tab-label::after{
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all .35s;
}

.tab-content{
  width: 100%;
  overflow: hidden;
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all .35s;
}


input:checked ~ .tab-content{
  max-height: 100vh;
}

input:checked + .tab-label {
    background: rgb(35, 52, 68);
}

input:checked + .tab-label::after {
  transform: rotate(90deg);
}
/* input:checked {
  + .tab-label {
    background: darken($midnight, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }
} */

/* .landing{
  margin-top: 0px;
  height: 100%;
}

.landing-top{
  height: 45%;
  width: 100%;
  opacity: 0.75;
  background-color: #00855d;
}


.landing-auth-buttons{
  margin: auto;
  display: flex;
  justify-content: center;
  opacity: 1;
  align-items: center;
}

.bg-img{
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  opacity: 0.1;
  width: 100%;
}

.landing-bottom{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
  padding: 50px;
}
 */
